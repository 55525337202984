import React from "react";
import { Grid, Typography, Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Importa useNavigate per la navigazione
import { Fade } from "react-awesome-reveal";

const PhotoGrid = ({ photos, isHomepage }) => {
  const navigate = useNavigate(); // Inizializza il navigatore
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const handleCardClick = (title) => {
    const formattedTitle = title.replace(/\s+/g, "-").toLowerCase(); // Formatta il titolo per la route

    if (isHomepage) {
      navigate(`/${formattedTitle}`); //
    } else navigate(`/photos/${formattedTitle}`); // Reindirizza alla route dinamica
  };

  return (
    <Grid
      container
      spacing={isMobile ? 6 : 4}
      sx={{
        width: "100%",
        padding: isMobile ? "0 1%" : undefined,
      }}
    >
      {photos.map((photo, index) => (
        <Grid
          item
          xs={12}
          md={6}
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Fade
            duration={1000}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              onClick={() => handleCardClick(photo.title)}
              style={{
                cursor: "pointer",
                width: "100%",
                height: isMobile ? "55vw" : "150vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxHeight: "400px",
                background: `url(${photo.src}) no-repeat center center`,
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              {" "}
              <div
                style={{
                  width: "100%",
                  height: "100%",

                  backgroundColor: "rgba(0, 0, 0, 0.163)",
                  position: "absolute",
                  zIndex: 1,
                  top: 0,
                  left: 0,
                }}
              ></div>
              <Typography
                variant="h5"
                sx={{
                  zIndex: 2,
                  fontFamily: "ivymode",
                  fontSize: isMobile ? "1rem" : "2.5rem",
                  letterSpacing: "2.5px",
                  textTransform: "uppercase",
                  color: "#fff",
                }}
              >
                {photo.title}
              </Typography>
            </Box>
          </Fade>
        </Grid>
      ))}
    </Grid>
  );
};

export default PhotoGrid;
