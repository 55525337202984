import React, { useState } from "react";
import {
  Stack,
  Button,
  Grid,
  Card,
  CardMedia,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  deleteObject,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import { Delete as DeleteIcon } from "@mui/icons-material";

const MegaForm2 = () => {
  const [macroCollection, setMacroCollection] = useState("Portrait");
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [photosInCategory, setPhotosInCategory] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const storage = getStorage();

  // Carica Foto nello Storage
  const handlePhotoUpload = (e) => {
    setUploadedPhotos(Array.from(e.target.files));
  };

  const handleSubmit = async () => {
    if (!macroCollection || uploadedPhotos.length === 0) {
      alert("Seleziona una collezione e carica almeno una foto!");
      return;
    }

    setIsUploading(true);

    try {
      for (let i = 0; i < uploadedPhotos.length; i++) {
        const photo = uploadedPhotos[i];
        const photoName = `photo_${Date.now()}_${i}.jpg`;
        const photoRef = storageRef(storage, `${macroCollection}/${photoName}`);

        await uploadBytes(photoRef, photo);
      }

      alert("Foto aggiunte con successo!");
      setUploadedPhotos([]);
      loadPhotos();
    } catch (error) {
      console.error("Errore durante il caricamento:", error);
      alert("Errore durante il caricamento, riprova.");
    } finally {
      setIsUploading(false);
    }
  };

  // Carica Foto da una Macro-Categoria
  const loadPhotos = async () => {
    if (!macroCollection) return;

    try {
      const folderRef = storageRef(storage, macroCollection);
      const result = await listAll(folderRef);

      const photoUrls = await Promise.all(
        result.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return { name: item.name, url };
        })
      );

      setPhotosInCategory(photoUrls);
    } catch (error) {
      console.error("Errore durante il caricamento delle foto:", error);
    }
  };

  // Elimina Foto direttamente dallo Storage
  const handleDeletePhoto = async (photoName) => {
    if (window.confirm("Sei sicuro di voler eliminare questa foto?")) {
      try {
        const photoRef = storageRef(storage, `${macroCollection}/${photoName}`);
        await deleteObject(photoRef); // Elimina l'oggetto
        alert("Foto eliminata con successo!");
        loadPhotos(); // Ricarica la lista delle foto
      } catch (error) {
        console.error("Errore durante l'eliminazione:", error);
        alert("Errore durante l'eliminazione, riprova.");
      }
    }
  };

  return (
    <Stack
      spacing={5}
      sx={{
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "90vw",
      }}
    >
      <h2>Carica Foto nella Macro-Categoria</h2>
      <label>
        Scegli Collezione:
        <select
          value={macroCollection}
          onChange={(e) => setMacroCollection(e.target.value)}
          style={{ padding: "0.5rem", marginLeft: "1rem" }}
        >
          <option value="">Seleziona</option>
          <option value="Portrait">Portrait</option>
          <option value="Travel">Travel</option>
          <option value="Diary">Diary</option>
          <option value="Events">Events</option>
          <option value="Home">Home</option>
          <option value="Mobile">Home Mobile</option>
        </select>
      </label>

      <label>
        Carica Foto:
        <input
          style={{ padding: "0.5rem" }}
          type="file"
          accept="image/*"
          multiple
          onChange={handlePhotoUpload}
        />
      </label>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={uploadedPhotos.length === 0 || isUploading}
      >
        {isUploading ? "Caricamento in corso..." : "Carica Foto"}
      </Button>

      {isUploading && (
        <CircularProgress
          size={30}
          sx={{ marginTop: "1rem", alignSelf: "center" }}
        />
      )}

      <h3>Foto in "{macroCollection}"</h3>
      <Button onClick={loadPhotos} variant="contained" color="secondary">
        Carica Foto Esistenti
      </Button>

      <Grid container spacing={2}>
        {photosInCategory.map((photo) => (
          <Grid item xs={2} key={photo.name}>
            <Card>
              <CardMedia
                component="img"
                image={photo.url}
                alt="Foto"
                style={{ height: 100, objectFit: "cover" }}
              />
              <IconButton
                color="error"
                onClick={() => handleDeletePhoto(photo.name)}
              >
                <DeleteIcon />
              </IconButton>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default MegaForm2;
