import React, { useState } from "react";
import Masonry from "react-masonry-css";
import "./styles.css";

const MasonryGrid = ({ photos }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const breakpointColumnsObj = {
    default: 3,

    1400: 3,
    800: 2,
    500: 2,
  };

  const handleImageClick = (index) => {
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setSelectedIndex(null);
  };

  const handleNext = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const handlePrev = () => {
    setSelectedIndex(
      (prevIndex) => (prevIndex - 1 + photos.length) % photos.length
    );
  };

  return (
    <div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {photos.map((photo, index) => (
          <div key={index} onClick={() => handleImageClick(index)}>
            <img
              src={photo}
              alt={""}
              style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
            />
          </div>
        ))}
      </Masonry>

      {selectedIndex !== null && (
        <div className="overlay" onClick={handleClose}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleClose}>
              &times;
            </button>
            <button className="prev-button" onClick={handlePrev}>
              &#10094;
            </button>
            <img
              src={photos[selectedIndex]}
              alt=""
              className="fullscreen-image"
            />
            <button className="next-button" onClick={handleNext}>
              &#10095;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MasonryGrid;
