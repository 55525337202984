import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Components/HomePage";
import PhotoGridGalleries from "./Components/PhotoGridGalleries";
import AppBarComp from "./Components/UI/AppBarComp";
import FooterLinks from "./Components/UI/FooterLinks";
import Contact from "./Components/Contact";
import Preloader from "./Components/Preloader"; // importa il Preloader
import "./App.css";
import MegaForm2 from "./Components/UI/Form/MegaForm2";

const App = () => {
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    // Verifica se è la prima visita dell'utente
    const isFirstVisit = localStorage.getItem("firstVisit");

    if (!isFirstVisit) {
      setShowPreloader(true); // Mostra il preloader se è la prima visita
      localStorage.setItem("firstVisit", "false"); // Imposta il flag in localStorage per non mostrare più il preloader
    }
  }, []);

  const handlePreloaderFinish = () => {
    setShowPreloader(false); // Nascondi il preloader dopo 5 secondi
  };

  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {showPreloader && <Preloader onFinish={handlePreloaderFinish} />}
      <AppBarComp />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/portrait"
            element={<PhotoGridGalleries galleryName="Portrait" />}
          />
          <Route
            path="/events"
            element={<PhotoGridGalleries galleryName="Events" />}
          />
          <Route
            path="/diary"
            element={<PhotoGridGalleries galleryName="Diary" />}
          />
          <Route
            path="/travel"
            element={<PhotoGridGalleries galleryName="Travel" />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin" element={<MegaForm2 />} />
          {/* Route dinamica */}
        </Routes>
      </Router>
      <FooterLinks />
    </div>
  );
};

export default App;
