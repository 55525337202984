import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage"; // Importa solo ciò che serve per Storage
import { getDatabase } from "firebase/database"; // Importa solo ciò che serve per il Realtime Database
import { getFirestore } from "firebase/firestore"; // Importa Firestore (se necessario)

// Configurazione di Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBXL7eoB9qbl6nfmEK4r8XTYLccxXmSItM",
  authDomain: "sergioandretti-d463c.firebaseapp.com",
  projectId: "sergioandretti-d463c",
  storageBucket: "sergioandretti-d463c.firebasestorage.app",
  databaseURL:
    "https://sergioandretti-d463c-default-rtdb.europe-west1.firebasedatabase.app",
  messagingSenderId: "382469734977",
  appId: "1:382469734977:web:e9343f29a68973563afcc0",
  measurementId: "G-Q479LL8PNC",
};

// Inizializza Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app); // Connessione a Firebase Storage

// Funzione dinamica per ottenere tutte le immagini da una cartella specificata
export const getImagesFromFolder = async (folderName, setLoading) => {
  try {
    // Imposta lo stato di caricamento a true all'inizio
    setLoading(true);

    // Crea un riferimento alla cartella specificata
    const folderRef = ref(storage, folderName);

    // Elenca tutti i file nella cartella
    const result = await listAll(folderRef);

    // Se ci sono file nella cartella
    if (result.items.length > 0) {
      // Mappa su tutti i file e ottieni gli URL
      const urls = await Promise.all(
        result.items.map(async (item) => {
          const url = await getDownloadURL(item); // Ottieni l'URL per ogni immagine
          return url; // Restituisci l'URL
        })
      );
      setLoading(false);

      return urls; // Restituisci un array di URL
    } else {
      console.log(`Nessuna immagine trovata nella cartella "${folderName}"`);
      setLoading(false); // Imposta lo stato di caricamento a false
      return []; // Restituisci un array vuoto se la cartella è vuota
    }
  } catch (error) {
    console.error("Errore nel recuperare le immagini:", error);
    setLoading(false); // Imposta lo stato di caricamento a false in caso di errore
    return []; // Restituisci un array vuoto in caso di errore
  }
};
// Esporta le istanze necessarie
export const database = getDatabase(app); // Connesso al Realtime Database
export const db = getFirestore(app); // Connesso a Firestore (se necessario)
