import React, { useEffect, useState } from "react";
import useGallery from "../hooks/useGallery";
import MasonryGrid from "./UI/MasonryGrid/MasonryGrid";
import { Box, CircularProgress } from "@mui/material";
import { getImagesFromFolder } from "../firebaseConfig";

const PhotoGridGalleries = ({ galleryName }) => {
  // const photos = useGallery(galleryName);
  const [images, setImages] = useState([]); // Stato per memorizzare gli URL delle immagini
  const [isLoading, setIsLoading] = useState(true); // Stato per gestire il caricamento

  useEffect(() => {
    const fetchImages = async () => {
      const urls = await getImagesFromFolder(galleryName, setIsLoading); // Passa la funzione setIsLoading
      setImages(urls); // Imposta lo stato con gli URL delle immagini
    };

    fetchImages(); // Esegui la funzione al montaggio del componente
  }, []); // Esegui solo una volta al montaggio del componente

  return (
    <div
      style={{
        height: isLoading ? "80vh" : "auto",
        minHeight: "80vh",
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "black",
            }}
          />
        </Box>
      ) : (
        <MasonryGrid photos={images} />
      )}
    </div>
  );
};

export default PhotoGridGalleries;
