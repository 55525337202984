import React, { useState, useEffect } from "react";
import LandingPage from "./LandingPage";
import PhotoGrid from "./PhotoGrid";
import { useMediaQuery } from "@mui/material";
import { getDatabase, ref, get } from "firebase/database"; // Importa solo ciò che serve

import image1 from "../assets/1/5.jpg";
import image2 from "../assets/1/events.jpg";
import image3 from "../assets/1/travel.jpg";
import image4 from "../assets/1/diary.jpg";
import { getFirstPortraitImage, getImagesFromFolder } from "../firebaseConfig";

const photos = [
  { src: image1, title: "Portrait" },
  { src: image2, title: "Events" },
  { src: image3, title: "Travel" },
  { src: image4, title: "Diary" },
];
const HomePage = () => {
  const [images, setImages] = useState([]); // Stato per memorizzare gli URL delle immagini
  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: isMobile ? "100%" : "95%",
        margin: isMobile ? "0" : "0 auto",
      }}
    >
      <LandingPage />
      <div style={{ height: isMobile ? "30px" : "70px" }} />
      <PhotoGrid photos={photos} isHomepage />{" "}
      {/* Mantieni questa parte se hai altre foto statiche */}
    </div>
  );
};

export default HomePage;
