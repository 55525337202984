// src/components/LandingPage.js
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Importa gli stili di base per il carosello
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { getImagesFromFolder } from "../firebaseConfig";

const LandingPage = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [images, setImages] = useState([]); // Stato per memorizzare gli URL delle immagini
  const [isLoading, setIsLoading] = useState(true); // Stato per gestire il caricamento

  useEffect(() => {
    const fetchImages = async () => {
      const folderName = isMobile ? "Mobile" : "Home"; // Determina la cartella in base al dispositivo

      const urls = await getImagesFromFolder(folderName, setIsLoading); // Passa la funzione setIsLoading
      setImages(urls); // Imposta lo stato con gli URL delle immagini
    };

    fetchImages(); // Esegui la funzione al montaggio del componente
  }, [isMobile]); // Esegui solo una volta al montaggio del componente

  const imageStyle = {
    width: "100%",
    height: isMobile ? "70vh" : "70vh",
    objectFit: isMobile ? "cover" : "contain",
    objectPosition: "center",
  };

  const mainContentStyle = {
    display: "flex",
    justifyContent: isMobile ? undefined : "center",
    width: "100%",
    height: isMobile ? undefined : "calc(100vh - 25vh)",
  };

  const carouselStyle = {
    width: isMobile ? "100%" : "85%",
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "black",
            }}
          />
        </Box>
      ) : (
        <div style={mainContentStyle}>
          <div style={carouselStyle}>
            <Carousel
              autoPlay
              interval={8000}
              infiniteLoop
              showThumbs={false}
              showStatus={false}
              showArrows={false}
            >
              {images.map((photo, index) => (
                <img
                  key={index}
                  src={photo}
                  alt={`Sergio Andretti ${index + 1}`}
                  style={imageStyle}
                />
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
};

export default LandingPage;
