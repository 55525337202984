import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  useMediaQuery,
  Stack,
  IconButton,
  Modal,
  Box,
  List,
} from "@mui/material";
import { Fade } from "react-awesome-reveal";
import CloseIcon from "@mui/icons-material/Close"; // Icona per chiudere il menu
import { Twirl as Hamburger } from "hamburger-react";

const AppBarComp = () => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Stato del menu mobile

  const appBarStyle = {
    backgroundColor: "#ffffff",
    boxShadow: "none",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: isMobile ? undefined : "center",
  };

  const toolbarStyle = {
    display: "flex",
    justifyContent: "space-between",
    height: isMobile ? "80px" : "180px",
    width: isMobile ? undefined : "90%",
    padding: isMobile ? "0 3.5%" : undefined,
  };

  const titleStyle = {
    fontFamily: "ivymode",
    fontWeight: 400,
    fontSize: isMobile ? "1rem" : "28px",
    letterSpacing: "2.8px",
    color: "#000",
  };

  const navButtonStyle = {
    fontFamily: "ivymode",
    textTransform: "none",
    fontSize: isMobile ? "1rem" : "12px",
    letterSpacing: "2px",
    fontWeight: 400,
    color: "#7c7c7c",
  };

  // Define the navigation links
  const navLinks = [
    { label: "Portrait", href: "/portrait" },
    { label: "Events", href: "/events" },
    { label: "Diary", href: "/diary" },
    { label: "Travel", href: "/travel" },
    { label: "Contact", href: "/contact" },
  ];

  // Toggle the menu open/close
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <AppBar position="static" style={appBarStyle}>
      <Toolbar style={toolbarStyle}>
        <Fade duration={1000}>
          <Typography variant="h6">
            <Button style={titleStyle} href={"/"}>
              SERGIO ANDRETTI
            </Button>
          </Typography>
        </Fade>

        {/* Hamburger Menu for mobile */}
        {isMobile ? (
          <Hamburger
            toggled={isMenuOpen}
            toggle={setIsMenuOpen}
            color="black"
            size={18}
          />
        ) : (
          // <IconButton onClick={toggleMenu} color="black">
          //   <MenuIcon />
          // </IconButton>
          // Desktop Navigation Links
          <Stack
            sx={{
              width: "35%",
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "row",
            }}
          >
            {navLinks.map((link, index) => (
              <Button key={index} style={navButtonStyle} href={link.href}>
                {link.label}
              </Button>
            ))}
          </Stack>
        )}
      </Toolbar>

      {/* Modal Menu for Mobile */}
      <Modal
        open={isMenuOpen}
        onClose={toggleMenu}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#fafafa",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: 24,
          }}
        >
          {/* Close button */}
          <IconButton
            onClick={toggleMenu}
            sx={{
              position: "absolute",
              top: 21,
              right: "4.5%",
              color: "black",
              zIndex: 10,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Mobile Navigation Links */}
          <List
            sx={{
              gap: "25px",
              display: "flex",
              flexDirection: "column",
              height: "90%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {navLinks.map((link, index) => (
              <Typography key={index} onClick={toggleMenu}>
                <Button
                  key={index}
                  sx={{
                    cursor: "pointer",
                    fontFamily: "ivymode",
                    fontSize: "2rem",
                    letterSpacing: "2px",
                    fontWeight: 400,
                    textTransform: "uppercase",
                    color: "black",
                  }}
                  href={link.href}
                >
                  {link.label}
                </Button>{" "}
              </Typography>
            ))}
          </List>
        </Box>
      </Modal>
    </AppBar>
  );
};

export default AppBarComp;
