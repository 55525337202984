import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, Button, Stack, Typography } from "@mui/material";
const FooterLinks = () => {
  return (
    <Stack
      spacing={12}
      sx={{
        justifyContent: "space-around",
        alignItems: "center",
        m: "100px 0",
        fontSize: "1.5rem",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography>
          <Button
            sx={{
              cursor: "pointer",
              fontFamily: "ivymode",
              fontSize: "1rem",
              letterSpacing: "2px",
              fontWeight: 400,
              textTransform: "uppercase",
              color: "black",
            }}
            href={"/contact"}
          >
            Contact
          </Button>{" "}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <a
          href="https://www.instagram.com/sergio.andretti/"
          style={{ color: "#a1a1a1", textDecoration: "none" }}
        >
          <Typography
            sx={{
              cursor: "pointer",
              fontFamily: "ivymode",
              fontSize: "1rem",
              letterSpacing: "2px",
              fontWeight: 400,
              textTransform: "uppercase",
              color: "black",
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            Follow Me <InstagramIcon fontSize="1.5rem" />
          </Typography>
        </a>
      </Box>
    </Stack>
  );
};

export default FooterLinks;
