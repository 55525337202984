import { CircularProgress, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

const Preloader = ({ onFinish }) => {
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const [isVisible, setIsVisible] = useState(true);
  const titleStyle = {
    fontFamily: "ivymode",
    fontWeight: 400,
    fontSize: isMobile ? "1.5rem" : "28px",
    letterSpacing: "2.8px",
    color: "#000",
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onFinish();
    }, 6000); // 5000ms = 5 secondi
    return () => clearTimeout(timer);
  }, [onFinish]);
  return (
    isVisible && (
      <div
        style={{
          gap: "30px",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ffffff",
          zIndex: 9999,
        }}
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 1 }} // Durata della transizione
      >
        {" "}
        <Typography variant="h6" style={titleStyle}>
          SERGIO ANDRETTI
        </Typography>
        <CircularProgress style={{ color: "#000" }} size={20} />
      </div>
    )
  );
};

export default Preloader;
